import videojs from 'video.js';


const Plugin = videojs.getPlugin('plugin');

// implement the plugin that adds the button the the controlBar
export class VideoJsExamplePlugin extends Plugin {

    constructor(player: videojs.Player, options: any) {
        super(player, options);
    }
}

const Tech = videojs.getTech('tech') as any as typeof videojs.Tech;

export class SpotifyTech extends Tech {
    constructor(options: SpotifyTechOptions, ready: videojs.Component.ReadyCallback) {
        super(options, ready);

        this.initSpotifyPlayer();
    }


    createEl() {
        const div = document.createElement('div');
        //div.setAttribute('id', this.options_.techId);
        div.setAttribute('style', 'width:100%;height:100%;top:0;left:0;position:absolute');
        div.setAttribute('class', 'vjs-tech');

        var divWrapper = document.createElement('div');
        divWrapper.appendChild(div);

        return divWrapper;
    }

    initSpotifyPlayer() {

    }

    static isSupported() {
        return true;
    };

    static canPlaySource(source: videojs.Tech.SourceObject) {
        return this.canPlayType(source.type);
    };

    static canPlayType(value: string | undefined): CanPlayTypeResult {
        return (value === 'video/spotify') ? 'probably' : '';
    };

    static parseUrl(url: string) {
        return {trackId: ''};
    };
}

videojs.registerTech('spotify', SpotifyTech);


export interface SpotifyTechOptions {
}