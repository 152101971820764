import * as React from 'react';
import {Box, Button, Modal, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {
    Annotation,
    ElemOrArray,
    InlineOrRef,
    getElemOrArray,
    WebResource,
    resolveWebResourceRef
} from "annotations-core";
import {useState} from "react";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export function AnnotationEditModal(props: {
    annotation: Annotation,
    open?: boolean,
    onClose?: () => void,
    onSave: (annotation: Annotation) => void,
    onDelete: (annotation: Annotation) => void
}) {

    const [editedAnnotation, setEditedAnnotation] = useState<Annotation>(JSON.parse(JSON.stringify(props.annotation)));
    /*
    useEffect(() => {
        setEditedAnnotation(JSON.parse(JSON.stringify(props.annotation)));
    }, [props.annotation]);

     */

    return <Modal
        open={!!props.open}
    >
        <Box component="form" sx={style}>
            <TextField label="Title"
                       value={editedAnnotation?.title}
                       onChange={
                           evt => setEditedAnnotation({...editedAnnotation, title: evt.target.value})
                       }/>
            {
                ('bodyValue' in editedAnnotation) ?
                    <TextField label="Content"
                               value={editedAnnotation?.title}
                               onChange={
                                   evt => setEditedAnnotation({...editedAnnotation, bodyValue: evt.target.value})
                               }/> :
                    <WebResourceArrayInput elems={editedAnnotation.body} onChange={(elems) => setEditedAnnotation({
                        ...editedAnnotation,
                        body: elems
                    })}/>
            }

            <Button variant="contained" onClick={() => editedAnnotation && props.onSave(editedAnnotation)}>Save</Button>
            <Button variant="contained"
                    onClick={() => editedAnnotation && props.onDelete(editedAnnotation)}>Delete</Button>
            <Button variant="contained" onClick={props.onClose}>Cancel</Button>
        </Box>
    </Modal>
}


export function WebResourceArrayInput(props: {
    elems: ElemOrArray<InlineOrRef<WebResource>>,
    onChange: (elems: WebResource[]) => void
}) {

    const elems = getElemOrArray(props.elems).map(resolveWebResourceRef);

    return <div>
        {elems.map((e, idx) => <WebResourceInput elem={e} key={idx}
                                                 onChange={(wr) => {
                                                     elems.splice(idx, 1, wr);
                                                     props.onChange(elems);
                                                 }}/>)}
    </div>
}

export function WebResourceInput(props: {
    elem: WebResource,
    onChange: (elem: WebResource) => void
}) {
    return <div>
        <ToggleButtonGroup
            color="primary"
            value={props.elem.type}
            exclusive
            onChange={
                (evt, value) => props.onChange({
                    ...props.elem,
                    type: value,
                })
            }
        >
            <ToggleButton value="TextualBody">Text</ToggleButton>
            <ToggleButton value="SpecificResource">Href</ToggleButton>
        </ToggleButtonGroup>
        {props.elem.type == 'TextualBody' ?
            <TextField label="Body Content"
                       value={props.elem.value}
                       onChange={
                           evt => props.onChange({
                               ...props.elem,
                               type: 'TextualBody',
                               value: evt.target.value
                           })
                       }/> : null}
        {props.elem.type == 'SpecificResource' ?
            <TextField label="Source"
                       value={props.elem.source}
                       onChange={
                           evt => props.onChange({
                               ...props.elem,
                               type: 'SpecificResource',
                               source: evt.target.value
                           })
                       }/> : null}
    </div>
}
