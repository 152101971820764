import * as React from 'react';
import { useMemo, useState} from 'react';
import {UnControlled as CodeMirror} from 'react-codemirror2';
import {AnnotationViewer} from '../annotation-viewer';
import {Annotation, getElemOrArray, Selector, WebResource} from 'annotations-core';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/turtle/turtle';
import 'codemirror/mode/javascript/javascript';
import {ErrorBoundary} from "@hilats/react-utils";

export function parseAnnotationsJSON(jsonStr?: string): [WebResource | undefined, Annotation[]] {
    const obj = jsonStr && JSON.parse(jsonStr);
    const annotations: Annotation[] = Array.isArray(obj) ? obj : obj ? [obj] : [];
    if (annotations.length == 0) return [undefined, []];
    else {
        let resourceOrString = getElemOrArray(annotations[0].target)[0];
        let resource: WebResource =
            typeof resourceOrString == 'string' ? {
                type: 'SpecificResource',
                source: resourceOrString
            } : resourceOrString;
        return [
            // default resource to
            resource,
            annotations
        ];
    }
}

/*
function useAnnotationContext(resourceWrapper: ResourceWrapper): AnnotationsContext {
    const [annotationsValue, setAnnotationsValue] = useState<string>();

    const [selection, setSelection] = useState<Selector[]>();

    const [fragments, setFragments] = useState<Selector[] | undefined>();

    const [htmlHelper, scrollStatus] = useHtmlWrapper(document, setFragmentsFn);


    // keep track of the current selection transposed into HTML fragments
    const selectedSegments$ = useMemo(
        () => {
            if (htmlHelper && fragments) {
                const segments = htmlHelper.createSegmentFromSelectors(fragments);
                return segments;
            } else {
                return Promise.resolve(undefined);
            }
        },
        [htmlHelper, fragments, scrollStatus]);

    return {
        annotations:
    }
}

 */

export const BasicViewer = (props: {}) => {
    const [annotationsValue, setAnnotationsValue] = useState<string>();

    const [selection, setSelection] = useState<Selector[]>();

    return (
        <div className="vFlow">
            <div style={{flex: 'none'}}>

                {/* Select box with a list of examples */}
                <select
                    onChange={event => {
                        const path = event.target.value;
                        if (path)
                            fetch(path)
                                .then(resp => resp.text())
                                .then(setAnnotationsValue);
                        else setAnnotationsValue('');
                    }}
                >
                    <option value=""> --</option>
                    <option value="/samples/annotation-pdf.json">PDF Annotations</option>
                    <option value="/samples/annotation-html.json">HTML Annotations</option>
                    <option value="/samples/annotation-inline-html.json">Inline HTML Annotations</option>
                    <option value="/samples/annotation-youtube.json">Youtube Annotations</option>
                    <option value="/samples/annotation-spotify.json">Spotify Annotations</option>
                    <option value="/samples/annotation-mp4.json">MP4 Annotations</option>
                </select>
            </div>

            {/* <SizeMeasurer>
            {({width, height}) => */}
            <div className="viewer-basic hFlow" /* style={{width, height}} */>
                <div className="vFlow">
                    <div style={{overflow: "scroll"}}>
                        {selection?.length ?
                            <pre>
                            {selection.map(frag => JSON.stringify(frag, undefined, 4))}
                        </pre> : null}
                    </div>
                    <CodeMirror
                        value={annotationsValue}
                        options={{
                            mode: 'javascript',
                            //theme: 'material',
                            lineNumbers: true
                        }}
                        onChange={(editor, data, value) => {
                            setAnnotationsValue(value);
                        }}
                    />
                </div>
                <div style={{flex: 2}}>
                    <ErrorBoundary>
                        <BasicContextViewer contextJsonStr={annotationsValue} onFragmentSelected={setSelection}
                                            selection={selection}/>
                    </ErrorBoundary>
                </div>
            </div>
            {/*    }
        </SizeMeasurer> */}
        </div>
    );
};


export const BasicContextViewer = (props: { contextJsonStr?: string, selection?: Selector[], onFragmentSelected?: (fragments?: Selector[]) => void, onNewAnnotation?: (fragments?: Selector[]) => void }) => {
    const [resource, annotations] = useMemo(() => parseAnnotationsJSON(props.contextJsonStr)
        , [props.contextJsonStr]);

    if (!resource) return null;

    return (
        <>
            {resource?.type == "SpecificResource" ?
                <input type="text" readOnly size={80} value={resource?.source}/> : null}
            {resource ? (
                <AnnotationViewer resource={resource} annotations={annotations}
                                  onFragmentSelected={props.onFragmentSelected}
                                  selection={props.selection}
                />
            ) : null}
        </>
    );
};
