import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Annotation, InlineOrRef, Selector, WebResource} from 'annotations-core';
import {TextViewer} from "./text-viewer";
import {
    ContentHelper,
    useAnnotationContext, WebResourceViewerComponent,
    findContentHelperWithHead
} from "annotations-react-ui";
import {proxify_url} from "@hilats/utils";
import {ErrorBoundary, SizeMeasurer} from "@hilats/react-utils";

export type WebResourceViewer = {
    canDisplay: (res: InlineOrRef<WebResource>) => boolean;
    renderComponent: WebResourceViewerComponent;
}


export const VIEWERS: ContentHelper[] = [
    // keep TextViewer last as it can act as a default viewer
    TextViewer
]

const proxifier = (url: string) => {
    return proxify_url(new URL(url), (global as any).PROXY_URL || new URL("/proxy", window.location.href).toString()).toString()
}

export const AnnotationViewer = (props: {
    resource: InlineOrRef<WebResource>;
    annotations?: Annotation[];
    highlightedAnnotations?: Annotation[];
    onFragmentSelected?: (fragments?: Selector[]) => void;
    onEditAnnotation?: (annotation?: Annotation) => void;
    selection?: Selector[];
}) => {

    const annotationContext = useAnnotationContext({
            annotations: props.annotations,
            selection: props.selection,
            onEditAnnotation: props.onEditAnnotation,
            onFragmentSelected: props.onFragmentSelected,
            highlightedAnnotations: props.highlightedAnnotations,
        }
    )

    const contentHelper$ = useMemo(() => findContentHelperWithHead(props.resource), [props.resource]);

    // re-implement PromiseContainer to avoid the 'undefined' transient contentHelper value caused by a change in props.resource
    const [contentHelper, setContentHelper] = useState<ContentHelper>();
    useEffect(() => {
        contentHelper$.then(setContentHelper, (err) => setContentHelper(undefined));
    }, [contentHelper$])

    return <ErrorBoundary>
        <SizeMeasurer>
            {({height, width}) =>
                contentHelper?.renderComponent ?
                    <contentHelper.renderComponent resource={props.resource}
                                                   context={annotationContext}
                                                   height={height}
                                                   width={width}
                                                   proxifier={proxifier}
                    /> :
                    <div>No viewer found</div>}
        </SizeMeasurer>
    </ErrorBoundary>;
};
