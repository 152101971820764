import * as ReactDOM from 'react-dom';
import * as React from 'react';
import {BrowserRouter, Link, Route, Routes} from 'react-router-dom';
import './mosaics.scss';
import {useContext, useEffect, useState} from 'react';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import {
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from 'reactstrap';
import {LogoutButton, SessionProvider, useSession} from '@inrupt/solid-ui-react';
import {Button} from '@material-ui/core';
import {BasicViewer} from './tools/basic-viewer';
import {PodViewerPanel} from "./tools/pod-viewer";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Tooltip} from "@mui/material";

import {getPodUrls} from "@hilats/solid-utils";
import {LoginMultiButton} from "./solid";
import {ErrorBoundary} from "@hilats/react-utils";


import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

(global as any).PROXY_URL = "https://demo.highlatitud.es/proxy";

export type AppContextType = {
    webId?: string,
    podUrl?: string,
    updateCtx: (update: Partial<AppContextType>) => void;
};

function createInitAppContext(updateAppContextFn: (update: Partial<AppContextType>) => void, basemap?: string): AppContextType {
    return {
        updateCtx: updateAppContextFn
    };
}

export const AppContext = React.createContext<AppContextType>(createInitAppContext(() => null));

export function AppContextProvider(props: { children: (ctx: AppContextType) => React.ReactNode }) {
    const {session} = useSession();

    //const params = new URLSearchParams(query);

    //if (!session.info.isLoggedIn)
    //    session.login({oidcIssuer:"https://inrupt.net", redirectUrl:window.location.href})

    const [appContext, setAppContext] = useState<AppContextType>(
        createInitAppContext(function (update) {
            setAppContext((prevCtx: AppContextType) => ({...prevCtx, ...update}));
        })
    );

    useEffect(
        () => {
            const podUrl$ = session.info.webId ? getPodUrls(session.info.webId, {fetch: session.fetch}).then(podUrls => podUrls[0]) : Promise.resolve(undefined);

            podUrl$.then(podUrl => {
                appContext.updateCtx({
                    webId: session.info.webId,
                    podUrl
                });
            })
        },
        // run this only once
        [session.info.webId]
    );

    return <AppContext.Provider value={appContext}>{props.children(appContext)}</AppContext.Provider>;
}

export const AppNavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {session} = useSession();
    const appContext = useContext(AppContext);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar expand="md">
            <NavbarBrand href="/">
                <img src="images/logo-big.png" height={60}/>
            </NavbarBrand>
            <NavbarToggler onClick={toggle}/>
            <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                    <NavItem>
                        <NavLink tag={Link} to="/route/select">
                            Menu1
                        </NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Tools
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem>
                                <NavLink tag={Link} to="/tools/basic-viewer">
                                    Basic Viewer
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink tag={Link} to="/tools/pod-viewer">
                                    Pod Viewer
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem divider/>
                            <DropdownItem>test2</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
                <Nav>
                    <NavItem>
                        {session.info.isLoggedIn ? (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <NavLink style={{display: 'inline'}} tag={Link} to="/user">
                                    <Tooltip
                                        title={
                                            <React.Fragment>
                                                <div>{appContext.webId}</div>
                                                <div>{appContext.podUrl}</div>
                                            </React.Fragment>
                                        }
                                    >
                                        <AccountCircleIcon />
                                    </Tooltip>
                                </NavLink>
                                <LogoutButton>
                                    <Button variant="contained" color="primary">
                                        Log&nbsp;out
                                    </Button>
                                </LogoutButton>
                            </div>
                        ) : (
                            <>
                                <LoginMultiButton
                                    authOptions={
                                        {
                                            //clientName: "Web Annotations App",
                                            clientId: "https://highlatitud.es/app/annotations.jsonld",
                                            redirectUrl: new URL("/tools/pod-viewer", window.location.href).toString(),

                                            tokenType: 'Bearer'
                                            /*, popUp: true */
                                        }
                                    }
                                    redirectUrl={new URL("/tools/pod-viewer", window.location.href).toString()}
                                    onError={console.log}
                                >
                                    <Button variant="contained" color="primary">
                                        Log&nbsp;in
                                    </Button>
                                </LoginMultiButton>
                            </>
                        )}
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

const routes = [
    {
        component: BasicViewer,
        path: '/tools/basic-viewer'
    },
    {
        component: PodViewerPanel,
        path: '/tools/pod-viewer'
    }
];

export const App = () => {
    return (
        <BrowserRouter>
            <SessionProvider restorePreviousSession={true} sessionId="mosaics-app" onError={console.log}>
                <AppContextProvider>
                    {ctx => (
                        <div className="mainApp vFlow">
                            <AppNavBar/>
                            <ErrorBoundary>
                                <Routes>
                                    <Route
                                        path="/"
                                        //component={...}
                                        element={<div>Main page</div>}
                                    />

                                    {routes.map((route, i) => (
                                        <Route path={route.path} key={i} element={<route.component/>} />
                                    ))}
                                </Routes>
                            </ErrorBoundary>
                        </div>
                    )}
                </AppContextProvider>
            </SessionProvider>
        </BrowserRouter>
    );
};

ReactDOM.render(<App/>, document.getElementById('index'));
