import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {AnnotationViewer} from '../annotation-viewer';
import {
    Annotation,
    BOOKMARKS_URL,
    getElemOrArray,
    WebResource,
    resolveWebResourceRef
} from 'annotations-core';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/turtle/turtle';
import 'codemirror/mode/javascript/javascript';
import {useSession} from "@inrupt/solid-ui-react";
import {DirtyCodemirror} from "./codemirror";
import {useFileAnnotationContainer} from "annotations-react-ui";
import {ErrorBoundary, PromiseContainer} from "@hilats/react-utils";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {AnnotationEditModal} from "./modals";
import {AppContext} from "../index";
import {TextField} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


export const PodViewerPanel = () => {

    const {fetch} = useSession();
    const appContext = useContext(AppContext)

    return <PodViewer podUrl={appContext.podUrl} fetch={fetch}/>
}


export const PodViewer = (props: { podUrl?: string, fetch?: typeof fetch }) => {

    const annotationContainer = useFileAnnotationContainer(
        props.podUrl && new URL(BOOKMARKS_URL, props.podUrl).toString(),
        props.fetch);

    const [selectedResource, setSelectedResource] = useState<WebResource>();

    const [selectedAnnotation, setSelectedAnnotation] = useState<Annotation>();

    const [editedAnnotation, setEditedAnnotation] = useState<Annotation>();

    useEffect(() => {
        selectedAnnotation && setSelectedResource(resolveWebResourceRef(getElemOrArray(selectedAnnotation.target)[0]))
    }, [selectedAnnotation]);

    return (
        <div className="vFlow">
            {editedAnnotation ? <AnnotationEditModal annotation={editedAnnotation}
                                                     open={!!editedAnnotation}
                                                     onSave={(a) => {
                                                         annotationContainer.setAnnotation(a);
                                                         setEditedAnnotation(undefined)
                                                     }}
                                                     onDelete={(a) => {
                                                         annotationContainer.deleteAnnotation(a);
                                                         setEditedAnnotation(undefined)
                                                     }}
                                                     onClose={() => setEditedAnnotation(undefined)}/> : null}
            {/* <SizeMeasurer>
            {({width, height}) => */}
            <div className="viewer-basic hFlow" /* style={{width, height}} */>
                <div className="vFlow">
                    {/*  Annotation list */}
                    <div className="annotationList">
                        <PromiseContainer promise={annotationContainer.annotations$}>
                            {(annotations) => <TabbedAnnotationList
                                selectedResourceUrl={selectedResource?.type == 'SpecificResource' ? selectedResource.source : undefined}
                                annotations={annotations}
                                setSelectedAnnotation={setSelectedAnnotation}
                                deleteAnnotation={annotationContainer.deleteAnnotation}
                                editAnnotation={setEditedAnnotation}/>}
                        </PromiseContainer>
                    </div>

                    {/*  Raw editor */}
                    <PromiseContainer promise={annotationContainer.rawContent$}>
                        {(rdfContent) => <DirtyCodemirror
                            value={rdfContent}
                            options={{
                                //theme: 'material',
                                lineNumbers: true
                            }}
                            onChange={((editor, data, value) => {
                                annotationContainer.saveRawFile(value)
                            })}
                        />}
                    </PromiseContainer>

                </div>
                <div style={{flex: 2}} className="vFlow">
                    {
                            <ErrorBoundary>
                                <div style={{flex: "none"}}><TextField fullWidth label="URL"
                                                                       value={selectedResource?.type == 'SpecificResource' ? selectedResource?.source : undefined}
                                                                       onChange={(evt) => setSelectedResource(resolveWebResourceRef(evt.target.value))}/>
                                </div>
                                <div style={{flexGrow: 1}}>
                                    {selectedResource ?
                                    <PromiseContainer promise={annotationContainer.annotations$}>
                                        {(annotations) =>

                                            <AnnotationViewer resource={selectedResource}
                                                              annotations={annotations}
                                                              onEditAnnotation={setEditedAnnotation}
                                                              highlightedAnnotations={selectedAnnotation ? [selectedAnnotation] : []}
                                            />}
                                    </PromiseContainer> : null}
                                </div>
                            </ErrorBoundary>
                    }
                </div>
                {/* view group of annotations

                    <div style={{flex: 2}}>
                    <PromiseContainer promise={bookmarks$}>
                        {(annotations) =>
                            <ErrorBoundary>
                                <AnnotationViewer resource={selectedAnnotation?.target}
                                                  annotations={annotations}
                                />
                            </ErrorBoundary>
                        }
                    </PromiseContainer>
                </div>
                */
                }

            </div>
            {/*    }
        </SizeMeasurer> */}
        </div>
    );
};

const publicAnnotations = require("./sampleAnnotations.json");

export const TabbedAnnotationList = (props: {
    selectedResourceUrl?: string,
    annotations: Annotation[],
    setSelectedAnnotation: (annotation: Annotation | undefined) => void,
    editAnnotation: (annotation: Annotation | undefined) => void,
    deleteAnnotation: (annotation: Annotation | undefined) => void
}) => {

    const [tab, setTab] = useState("public");

    useEffect(() => {
        if (props.annotations.length) {
            if (props.selectedResourceUrl) setTab("resource");
            else setTab("all");
        }
    }, [props.selectedResourceUrl])

    return <div>
        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} centered>
            <Tab value="public" label="Public"/>
            <Tab value="all" label="All"/>
            <Tab value="resource" label="Filtered"/>
        </Tabs>
        <div hidden={tab !== "all"}>
            <AnnotationList {...props}/>
        </div>
        <div hidden={tab !== "resource"}>
            <AnnotationList {...props} annotations={props.annotations.filter(a => {
                const target = getElemOrArray(a.target).map(resolveWebResourceRef);
                return target.find(t => t.type == 'SpecificResource' && t.source == props.selectedResourceUrl);
            })}/>
        </div>
        <div hidden={tab !== "public"}>
            <AnnotationList annotations={publicAnnotations} setSelectedAnnotation={props.setSelectedAnnotation}/>
        </div>
    </div>
}


export const AnnotationList = (props: {
    annotations: Annotation[],
    setSelectedAnnotation: (annotation: Annotation | undefined) => void,
    editAnnotation?: (annotation: Annotation | undefined) => void,
    deleteAnnotation?: (annotation: Annotation | undefined) => void
}) => {
    return <>{props.annotations.sort((a, b) => (!a.modified || !b.modified) ? 0 : a.modified.localeCompare(b.modified)).map((annotation, idx) =>
        <AnnotationLine key={idx} annotation={annotation}
                        deleteAnnotation={props.deleteAnnotation}
                        editAnnotation={props.editAnnotation}
                        setSelectedAnnotation={props.setSelectedAnnotation}/>)}</>;
}

export const AnnotationLine = (props: {
    annotation: Annotation,
    setSelectedAnnotation: (annotation: Annotation | undefined) => void,
    deleteAnnotation?: (annotation: Annotation | undefined) => void,
    editAnnotation?: (annotation: Annotation | undefined) => void,
}) => {
    return <div className="annotationLine">
        <span onClick={() => props.setSelectedAnnotation(props.annotation)}>{props.annotation.title}</span>
        {props.editAnnotation ? <EditIcon onClick={() => props.editAnnotation && props.editAnnotation(props.annotation)}/> : null}
        {props.deleteAnnotation ? <DeleteIcon onClick={() => props.deleteAnnotation && props.deleteAnnotation(props.annotation)}/> : null }
    </div>
}
