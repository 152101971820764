import * as React from "react";
import {IUnControlledCodeMirror, UnControlled as CodeMirror} from "react-codemirror2";
import {useCallback, useEffect, useState} from "react";
import {annotationsFrame, frameRdfStrings, reverseFrame} from "annotations-core";
import {Button, ButtonGroup} from "reactstrap";
import {Editor} from "codemirror";

type TypeContent = { raw: string, encoding: 'JSON' | 'Turtle' };

async function convertContent(content: TypeContent, newEncoding: string): Promise<TypeContent> {
    if (content.encoding == newEncoding)
        return content;
    else if (content.encoding == "JSON" && newEncoding == 'Turtle') {
        const ttlStr = await reverseFrame(JSON.parse(content.raw), annotationsFrame);
        return {raw: ttlStr, encoding: newEncoding};
    } else if (content.encoding == "Turtle" && newEncoding == 'JSON') {
        const jsonStr = await frameRdfStrings([content.raw], annotationsFrame).then(obj => JSON.stringify(obj, undefined, 4));
        return {raw: jsonStr, encoding: newEncoding};
    } else
        throw new Error(`Unsupported encodings ${content.encoding} , ${newEncoding}`);
}

export const DirtyCodemirror = (props: IUnControlledCodeMirror) => {
    const [currentContent, setCurrentContent] = useState<TypeContent>({raw: props.value || '', encoding: "Turtle"});

    useEffect(() => {
        setCurrentContent({raw: props.value || '', encoding: "Turtle"});
    }, [props.value]);

    const isDirty = currentContent.raw != (props.value || '');

    const onBlurCb = useCallback((editor: Editor) => setCurrentContent({
        raw: editor.getValue(),
        encoding: currentContent.encoding
    }), [setCurrentContent, currentContent])

    // because of this codemirror bug, the CodeMirror element is not updated on callback changes. https://github.com/scniro/react-codemirror2/issues/142
    // --> force the update with a key change
    return <div style={{position: "relative"}}>
        {currentContent.encoding}
        <CodeMirror key={currentContent.encoding} {...{
            ...props,
            onChange: undefined,
            options: {...props.options, mode: currentContent.encoding == 'JSON' ? 'json' : 'turtle'}
        }} onBlur={onBlurCb} value={currentContent.raw}/>
        <div style={{position: "absolute", right: "5px", top: "5px", zIndex: 10}}>
            <ButtonGroup>
                <Button color="primary" onClick={() => convertContent(currentContent, "JSON").then(setCurrentContent)}
                        active={currentContent.encoding == "JSON"}>JSON</Button>
                <Button color="primary" onClick={() => convertContent(currentContent, "Turtle").then(setCurrentContent)}
                        active={currentContent.encoding == "Turtle"}>Turtle</Button>
            </ButtonGroup>
            {isDirty ? <Button
                color="secondary"
                onClick={() => props.onChange && convertContent(currentContent, "Turtle").then(ttlContent => props.onChange && props.onChange(undefined as any, undefined as any, ttlContent.raw))}>Save</Button> : null}
        </div>
    </div>
}
