import * as React from 'react';

import 'video.js/dist/video-js.css';
import 'videojs-youtube/dist/Youtube.min.js';
import '../videojs-spotify/spotify-plugin';
import {ContentHelper, WebResourceViewerComponent} from "annotations-react-ui";
import {getResourceContent, resolveWebResourceRef, ResourceWrapper, WebResource} from "annotations-core";
import {getFileMimeType, WELL_KNOWN_TYPES} from "@hilats/utils";
import {PromiseFnContainer} from "@hilats/react-utils";


export const TextViewerComponent: WebResourceViewerComponent = (props) => {
    const resource = resolveWebResourceRef(props.resource);

    return <PromiseFnContainer promiseFn={() => getResourceContent(resource)} deps={[props.resource]}>
        {value => <div>
            {value}
        </div>}
    </PromiseFnContainer>;
};

const SUPPORTED_MEDIA: string[] = [
    WELL_KNOWN_TYPES.txt,
]

export const TextViewer: ContentHelper = {
    accepts: (res: WebResource) => {
        if (res.type == 'TextualBody')
            return true;
        else if (res.type == 'SpecificResource') {
            const mimeType = res.format || getFileMimeType(res.source);
            return !!(mimeType && SUPPORTED_MEDIA.includes(mimeType));
        } else {
            return false;
        }

    },

    renderComponent: TextViewerComponent,

    overlayComponent: (props: {}) => null,

    createDocumentWrapper(...args): ResourceWrapper {
        throw new Error("Unsupported");
    }
}
